import { ArrowForward } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Grid, Link as MuiLink, Stack, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';

import { COLOR_VARIANTS, SIZES } from '../constants';
import { Button, Wrapper } from './BentoBox.style';

const gridItemSize = {
  [SIZES.HERO]: 12,
  [SIZES.FULL_SIZE]: 12,
  [SIZES.HALF_SIZE]: 6,
  [SIZES.TWO_THIRD]: 8,
  [SIZES.ONE_THIRD]: 4,
  [SIZES.ONE_QUARTER]: 3,
};

const headingVariant = {
  [SIZES.HERO]: 'h2Redesign',
  [SIZES.FULL_SIZE]: 'h3Redesign',
  [SIZES.HALF_SIZE]: 'h3Redesign',
  [SIZES.TWO_THIRD]: 'h3Redesign',
  [SIZES.ONE_THIRD]: 'h3Redesign',
  [SIZES.ONE_QUARTER]: 'h4Redesign',
};

const isValidNumber = value => {
  return typeof value === 'number' && !Number.isNaN(value) && isFinite(value);
};

const getGradientStartPoint = (startPoint, size) => {
  const minStartPoint = [SIZES.HERO, SIZES.FULL_SIZE].includes(size) ? 30 : 45;
  const gradientStartPoint = isValidNumber(startPoint) ? startPoint : 0;
  return gradientStartPoint > minStartPoint
    ? gradientStartPoint
    : minStartPoint;
};

const BentoBox = ({
  colorVariant,
  size,
  title,
  description,
  image,
  link,
  tag,
}) => {
  const mobileMediaQuery = useMobileMediaQuery();
  const [gradientStartPoint, setGradientStartPoint] = useState(0);
  const wrapperRef = useRef(null);

  const originalImageWidth = image.data?.attributes?.width;
  const originalImageHeight = image.data?.attributes?.height;
  const aspectRatio =
    originalImageWidth && originalImageHeight
      ? originalImageWidth / originalImageHeight
      : null;

  useEffect(() => {
    const calculateBackgroundImageWidth = () => {
      if (wrapperRef.current && aspectRatio) {
        const containerHeight = wrapperRef.current.clientHeight;
        const containerWidth = wrapperRef.current.clientWidth;
        const calculatedWidth = containerHeight * aspectRatio;
        const backgroundImageWidth =
          calculatedWidth > containerWidth ? containerWidth : calculatedWidth;
        const startPoint = (1 - backgroundImageWidth / containerWidth) * 100;

        setGradientStartPoint(getGradientStartPoint(startPoint, size));
      }
    };

    calculateBackgroundImageWidth();

    window.addEventListener('resize', calculateBackgroundImageWidth);

    return () => {
      window.removeEventListener('resize', calculateBackgroundImageWidth);
    };
  }, [aspectRatio]);

  return (
    <Grid item xs={mobileMediaQuery ? 12 : gridItemSize[size]}>
      <Wrapper
        colorVariant={colorVariant}
        size={size}
        image={image.data?.attributes.url}
        ref={wrapperRef}
        gradientStartPoint={gradientStartPoint}
        {...(link.data
          ? {
              component: NextLink,
              href: link.data.attributes.url,
            }
          : {})}>
        <Stack
          className="content-wrapper"
          alignItems={size === SIZES.ONE_QUARTER ? 'center' : 'initial'}
          textAlign={size === SIZES.ONE_QUARTER ? 'center' : 'left'}
          sx={{ height: '100%' }}>
          <Chip size="small" className="chip" label={tag} />
          <Typography
            component="h2"
            variant={headingVariant[size]}
            className="heading">
            {title}
          </Typography>
          {description && size === SIZES.HERO && (
            <Typography
              className="description"
              variant="body1"
              mt={3.75}
              mb={3}>
              {description}
            </Typography>
          )}
          {link.data &&
            (size === SIZES.HERO ? (
              <Button size="large" endIcon={<ArrowForward />} component="div">
                <Box component="span" mt="2px">
                  {link.data.attributes.text}
                </Box>
              </Button>
            ) : (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                className="link">
                <MuiLink color="primary" component="span">
                  {link.data.attributes.text}
                </MuiLink>
                <ArrowForwardIcon />
              </Stack>
            ))}
        </Stack>
      </Wrapper>
    </Grid>
  );
};

BentoBox.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(SIZES)).isRequired,
  colorVariant: PropTypes.oneOf(Object.values(COLOR_VARIANTS)).isRequired,
  tag: PropTypes.string.isRequired,
  description: PropTypes.string,
  link: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      attributes: PropTypes.shape({
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
  image: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      attributes: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
      }),
    }),
  }),
};

export default BentoBox;
