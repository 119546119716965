export const SIZES = {
  HERO: 'hero',
  FULL_SIZE: 'full-size',
  HALF_SIZE: 'half-size',
  TWO_THIRD: 'two-third',
  ONE_THIRD: 'one-third',
  ONE_QUARTER: 'one-quarter',
};

export const COLOR_VARIANTS = {
  PRIMARY: 'primary',
  PRIMARY_DARK: 'primaryDark',
  PRIMARY_LIGHT: 'primaryLight',
  GRAY: 'gray',
};
