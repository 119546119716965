import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import BentoBox from './BentoBox/BentoBox';
import { COLOR_VARIANTS, SIZES } from './constants';

const BentoGrid = ({ boxes }) => {
  return (
    <Grid container columnSpacing={2.5} rowSpacing={6.5}>
      {boxes.map(box => (
        <BentoBox key={box.id} {...box} />
      ))}
    </Grid>
  );
};

BentoGrid.propTypes = {
  boxes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      size: PropTypes.oneOf(Object.values(SIZES)).isRequired,
      colorVariant: PropTypes.oneOf(Object.values(COLOR_VARIANTS)).isRequired,
      tag: PropTypes.string.isRequired,
      description: PropTypes.string,
      link: PropTypes.shape({
        data: PropTypes.shape({
          id: PropTypes.number.isRequired,
          attributes: PropTypes.shape({
            text: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
          }),
        }),
      }).isRequired,
      image: PropTypes.shape({
        data: PropTypes.shape({
          id: PropTypes.number.isRequired,
          attributes: PropTypes.shape({
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired,
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    }).isRequired
  ).isRequired,
};

export default BentoGrid;
