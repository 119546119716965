import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import ButtonBase from '@/components/ui/Button/Button';
import theme from '@/styles/theme';

import { COLOR_VARIANTS, SIZES } from '../constants';

const colorVariants = {
  [COLOR_VARIANTS.PRIMARY]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    descriptionColor: theme.palette.primary.contrastText,
    chipColor: theme.palette.primary.main,
  },
  [COLOR_VARIANTS.PRIMARY_DARK]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    descriptionColor: theme.palette.primary.contrastText,
    chipColor: theme.palette.primary.dark,
  },
  [COLOR_VARIANTS.PRIMARY_LIGHT]: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    descriptionColor: theme.palette.text.primary,
    chipColor: theme.palette.primary.dark,
  },
  [COLOR_VARIANTS.GRAY]: {
    backgroundColor: '#EFEFEF',
    color: theme.palette.primary.dark,
    descriptionColor: theme.palette.text.primary,
    chipColor: theme.palette.primary.dark,
  },
};

const getContentWidth = size => {
  const contentWidth = {
    [SIZES.HERO]: '50%',
    [SIZES.FULL_SIZE]: '50%',
    [SIZES.HALF_SIZE]: '65%',
    [SIZES.TWO_THIRD]: '62%',
    [SIZES.ONE_THIRD]: '100%',
    [SIZES.ONE_QUARTER]: '100%',
  };

  return contentWidth[size];
};

const getMinHeight = size => {
  const minHeight = {
    [SIZES.HERO]: 511,
    [SIZES.FULL_SIZE]: 315,
    [SIZES.HALF_SIZE]: 400,
    [SIZES.TWO_THIRD]: 405,
    [SIZES.ONE_THIRD]: 405,
    [SIZES.ONE_QUARTER]: 350,
  };

  return minHeight[size];
};

export const Wrapper = styled(Box, {
  shouldForwardProp: prop =>
    !['colorVariant', 'size', 'image', 'gradientStartPoint'].includes(prop),
})(({ theme, colorVariant, size, image, gradientStartPoint, href }) => ({
  position: 'relative',
  backgroundColor: colorVariants[colorVariant].backgroundColor,
  borderRadius: 15,
  width: '100%',
  height: '100%',
  padding:
    size === SIZES.HERO
      ? theme.spacing(4.5, 9.5, 10)
      : theme.spacing(4.5, 3.75, 5),
  transition: 'opacity 0.2s ease-in-out',

  ...(href && {
    cursor: 'pointer',
    display: 'block',
    '&:hover': {
      opacity: 0.9,
    },
  }),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(5, 3.5),
  },

  [theme.breakpoints.up('md')]: {
    minHeight: getMinHeight(size),

    ...(image &&
      ![SIZES.ONE_THIRD, SIZES.ONE_QUARTER].includes(size) && {
        backgroundImage: `url(${image})`,
        backgroundPosition: 'right center',
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',

        '&:after': {
          borderRadius: 15,
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          background: `linear-gradient(to right, ${
            colorVariants[colorVariant].backgroundColor
          } ${gradientStartPoint}%, transparent ${gradientStartPoint + 20}%)`,
        },
      }),
  },

  '.heading': {
    color: colorVariants[colorVariant].color,
    textOverflow: 'ellipsis',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      ...theme.typography.h4Redesign,
    },
  },

  '.link': {
    marginTop: 'auto',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3.5),
      paddingTop: 0,
    },
    '& *': {
      color: colorVariants[colorVariant].color,
      textDecorationColor: colorVariants[colorVariant].color,
    },
  },
  '.content-wrapper': {
    zIndex: 1,
    position: 'relative',

    [theme.breakpoints.up('md')]: {
      width: getContentWidth(size),
    },

    [theme.breakpoints.down('md')]: {
      padding: 0,
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  '.description': {
    color: colorVariants[colorVariant].descriptionColor,

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  '.chip': {
    alignSelf: size === SIZES.ONE_QUARTER ? 'center' : 'flex-start',
    textTransform: 'uppercase',
    padding: theme.spacing(0, 2.5),
    backgroundColor: 'rgba(255, 255, 255, 0.56)',
    color: colorVariants[colorVariant].chipColor,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
    },
  },
}));

export const Button = styled(ButtonBase)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.common.white,
  fontSize: theme.typography.pxToRem(13),
  alignSelf: 'flex-start',
  padding: theme.spacing(1.5, 5),
  marginTop: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    alignSelf: 'center',
  },
}));
